@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');

@import '~bootstrap/dist/css/bootstrap.css';

@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
@import '~admin-lte/dist/css/adminlte.min.css';

@import '~react-toastify/scss/main';

#root {
    width: 100vw;
    height: 100vh;
}

.main-header .navbar-nav .nav-item .nav-link {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.navbar-nav > .user-menu .user-image {
    height: 1.6rem;
    width: 1.6rem;
    margin-right: 0;
    margin-left: -8px;
}

.user-block {
    float: left;
}

.user-block img {
    float: left;
    height: 40px;
    width: 40px;
}

.header-block .line1,
.header-block .line2,
.header-block .line3 {
    display: block;
}

.header-block .line1 {
    font-size: 16px;
    font-weight: 600;
    margin-top: -1px;
}

.header-block .line2 {
    color: #6c757d;
    font-size: 13px;
    margin-top: -3px;
}

.header-block.header-block-sm img {
    width: 1.875rem;
    height: 1.875rem;
}

.header-block.header-block-sm .line1 {
    font-size: 14px;
}
