.files input {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    -webkit-transition: outline-offset 0.15s ease-in-out,
        background-color 0.15s linear;
    transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
    padding: 20px 0px 50px 10%;
    text-align: center !important;
    margin: 0;
    width: 100% !important;
}
.files input:focus {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    -webkit-transition: outline-offset 0.15s ease-in-out,
        background-color 0.15s linear;
    transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
    border: 1px solid #92b0b3;
}
.files {
    position: relative;
}
/* .files:after {
  pointer-events: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 50px;
  right: 0;
  height: 56px;
  content: "";
  background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
} */
.color input {
    background-color: #f1f1f1;
}

.form-group {
    margin-bottom: 0;
}

textarea.form-control {
    height: 120px;
}

.files:before {
    position: absolute;
    bottom: 0px;
    left: 0;
    pointer-events: none;
    width: 100%;
    right: 0;
    height: 57px;
    /* content: " or drag it here. "; */
    display: block;
    margin: 0 auto;
    color: #2ea591;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
}
